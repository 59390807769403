import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../shared/components/TextInput";
import { ButtonPrimary } from "../shared/components/button/Buttons";
import { subscribeUserToNewsletter } from "../services/user";
import {
  toastError,
  toastNotification,
  toastSuccess,
} from "../utils/ToastMessage";
import { NewsletterCoverBagkground } from "../assets/images/image";
import useEnterKeyPress from "../utils/useEnterKeyPress";

type Props = {};

const NewsletterSection = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleSubscribeUserToNewsletter = async () => {
    setLoading(true);
    try {
      await subscribeUserToNewsletter({ email: email })
        .then((res: any) => {
          if (res?.isSubscribed) {
            toastSuccess(
              "Successfully subscribed.",
              "User is successfully subscribed to newsletter."
            );
          } else {
            toastNotification(res?.response?.data?.message);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.message === "This email is already Subscribed."
          ) {
            toastNotification(err?.response?.data?.message);
          } else {
            toastError(
              "Ops! Something went wrong.",
              err?.response?.data?.message
            );
          }
        });
    } catch (error: any) {
      toastError(
        "Ops! Something went wrong.",
        "Failed to subscribe. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  
  const handleEnterKeyPress = () => {
    handleSubscribeUserToNewsletter();
 };

 // Use the custom hook
 useEnterKeyPress(handleEnterKeyPress);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: { xs: "24px", md: "32px" },
        borderRadius: "24px",
        bgcolor: "#1b1b1b",
        // boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
        padding: { xs: "40px 24px", sm: "40px", lg: "80px" },
        backgroundImage: `url(${NewsletterCoverBagkground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "end",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: { xs: "12px", md: "24px" },
          maxWidth: { xs: "100%", md: "60%", lg: "45%" },
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "text.secondary",
            fontSize: "58px",
            background:
              "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h1"
        >
          Join our newsletter and stay updated!
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            color: "rgba(255, 255, 255, 0.5)",
            maxWidth: { xs: "100%", lg: "80%" },
            width: "100%",
          }}
          variant="subtitle1"
        >
          No Spam. Only valuable content and updates on our products. Join
          5,000+ other creators who enjoy our insights.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "339px" } }}>
          <TextInput
            inputStyles={{
              backgroundColor: "#1B1B1B",
              fontSize: "18px",
              fontWeight: "400",
              width: "100%",
            }}
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            placeholderFontSize="18px"
            placeholder="Email address..."
          />
        </Box>

        <ButtonPrimary
          LabelStyle={{ fontSize: "18px", fontWeight: "600" }}
          sx={{
            width: { xs: "100%", sm: "126px" },
            maxWidth: { xs: "100%", sm: "339px" },
          }}
          onClick={() => {
            handleSubscribeUserToNewsletter();
          }}
          isLoading={loading}
          label="Submit"
        />
      </Box>
    </Box>
  );
};

export default NewsletterSection;
