import React from 'react'

const LockIcon = () => {
  return (
    <svg width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.1719 136.328C30.1719 114.879 30.1719 104.155 36.8352 97.4914C43.4985 90.8281 54.223 90.8281 75.6719 90.8281H136.339C157.787 90.8281 168.512 90.8281 175.175 97.4914C181.839 104.155 181.839 114.879 181.839 136.328C181.839 157.777 181.839 168.501 175.175 175.165C168.512 181.828 157.787 181.828 136.339 181.828H75.6719C54.223 181.828 43.4985 181.828 36.8352 175.165C30.1719 168.501 30.1719 157.777 30.1719 136.328Z" fill="white" fill-opacity="0.06" stroke="#363636" stroke-width="1.03019"/>
<path d="M75.6615 143.917C79.8496 143.917 83.2448 140.521 83.2448 136.333C83.2448 132.145 79.8496 128.75 75.6615 128.75C71.4733 128.75 68.0781 132.145 68.0781 136.333C68.0781 140.521 71.4733 143.917 75.6615 143.917Z" fill="#CCFF00"/>
<path d="M105.995 143.917C110.183 143.917 113.578 140.521 113.578 136.333C113.578 132.145 110.183 128.75 105.995 128.75C101.807 128.75 98.4115 132.145 98.4115 136.333C98.4115 140.521 101.807 143.917 105.995 143.917Z" fill="#CCFF00"/>
<path d="M143.911 136.333C143.911 140.521 140.516 143.917 136.328 143.917C132.14 143.917 128.745 140.521 128.745 136.333C128.745 132.145 132.14 128.75 136.328 128.75C140.516 128.75 143.911 132.145 143.911 136.333Z" fill="#CCFF00"/>
<path d="M66.1875 75.6719C66.1875 53.684 84.0122 35.8594 106 35.8594C127.988 35.8594 145.813 53.684 145.813 75.6719V90.8656C150.115 90.9058 153.877 91.0054 157.188 91.2527V75.6719C157.188 47.4018 134.27 24.4844 106 24.4844C77.7299 24.4844 54.8125 47.4018 54.8125 75.6719V91.2527C58.1227 91.0054 61.885 90.9058 66.1875 90.8656V75.6719Z" fill="white" fill-opacity="0.06" stroke="#363636" stroke-width="1.03019" stroke-linecap="square"/>
</svg>

  )
}

export default LockIcon
