import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  ButtonPrimary,
} from "../shared/components/button/Buttons";
import { getUserIdFromCookies } from "../utils/extensions";
import { toastSuccess } from "../utils/ToastMessage";
import RightTick from "../assets/icons/RightTickIcon/RightTick";
import useEnterKeyPress from "../utils/useEnterKeyPress";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  setActiveDialog: any;
  email?: string;
  redirecturl?:string
};

const ResetPasswordRecoveryMailSentPopup: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleContinue = async () => {
   if(props?.redirecturl) {
       window.location.href = props?.redirecturl;
       props?.handleDialog?.();
   }
   navigate("/")
  };


  const handleEnterKeyPress = () => {
     handleContinue();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
            // border:"1px solid red",
          }}
        > 
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100%", 
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          <Box
            sx={{
              maxWidth: "516px",
            //   maxWidth: "100%",
              width:"100%",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                zIndex:999999999
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setActiveDialog(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box sx={{marginTop:"44.5px"}}>
                <RightTick/>
              </Box>

              <Box
                sx={{
                  height: "50%",
                maxWidth: "516px",
                //   maxWidth: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "600",
                      color: "#FFFFFF",
                      mb: "12px",
                      textAlign: "center",
                    }}
                  >
                    Recovery email sent!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#FFFFFF",
                      opacity: "50%",
                      padding: "0 41px",
                      textAlign: "center",
                    }}
                  >
                    A Link to reset your password has been sent to you on <br /> {props.email ? props.email:""} 
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "300%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ButtonPrimary
                    label="Continue"
                    sx={{
                      width: "100%",
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000000",
                      margin:"32px 40px 40px 40px",
                      borderRadius:"16px",
                      paddingY:"16px",
                    }}
                    onClick={() => {
                      handleContinue();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ResetPasswordRecoveryMailSentPopup;
